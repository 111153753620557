import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import ColorSwatches from "~/components/ColorSwatches/ColorSwatches";
export const _frontmatter = {
  "menuLabel": "Color System",
  "sortOrder": 6.1
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Color System`}</h1>
    <p className="p-large">A palette for any of the Caesars brands — current and future.</p>
    <p>{`We've created a flexible color system that can be adopted for any theme. `}</p>
    <p>{`The system has built-in accessibility for WCAG 2.1 AA standards. All colors at the 600 level and above, regardless of hue, pass 3:1 contrast ratio against levels 200 and below. And all colors at the 700 level and above hit 4.5:1 contrast ratios against levels 200 and below.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Looking to use these? We've curated color collections for each digital product through `}<a parentName="p" {...{
          "href": "/tokens/colors"
        }}>{`color tokens.`}</a>{` `}</p>
    </blockquote>
    <ColorSwatches page="color-system" mdxType="ColorSwatches" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      